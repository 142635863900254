import NextLink from 'next/link';

import { ApiMomentFragment } from 'gql/api/api-fragments.generated';
import { getPath } from 'utils/router';

import { WorldOverview } from 'types/World';

import DetailsTag from './DetailsTag';
import { MomentHoverCard } from './MomentHoverCard';
import Box from './base/Box';
import Button, { ButtonVariants } from './base/Button';

type MomentTagProps =
  /** Links to the world */
  | {
      type: 'button';
      // Note: Only the raised, ghost, and outline variants are currently
      // tested. More changes will be needed if working with other variants.
      moment: ApiMomentFragment;
      world: WorldOverview;
      size?: ButtonVariants['size'];
      variant?: ButtonVariants['variant'];
    }
  /** Links to both the world and curator */
  | {
      type?: 'link';
      moment: ApiMomentFragment;
      world: WorldOverview;
      size?: ButtonVariants['size'];
    }
  /** Looks like a link, but has no hover cards or interactive elements */
  | {
      type: 'static';
      moment: ApiMomentFragment;
      world: WorldOverview;
      showCuratorFee?: boolean;
    }
  /** For rendering in a menu or list */
  | {
      type: 'list-item';
      moment: ApiMomentFragment;
      world: WorldOverview;
    };

export function MomentTag(props: MomentTagProps) {
  const momentHref = getPath.moment.page({
    momentId: props.moment.id,
    worldSlug: props.world.slug,
  });

  if (props.type === 'button') {
    return (
      <Box css={{ display: 'flex', minWidth: 0 }}>
        <MomentHoverCard world={props.world} moment={props.moment}>
          <NextLink href={momentHref} passHref>
            <Button
              size={props.size}
              css={{
                padding: '$2',
                paddingRight: '$3',
                borderRadius: '$3',
                height: 'auto',
              }}
              as="a"
              variant={props.variant || 'raised'}
            >
              <DetailsTag.Moment
                moment={{
                  ...props.moment,
                  worldImageUrl: props.world.imageUrl,
                  worldName: props.world.name,
                }}
              />
            </Button>
          </NextLink>
        </MomentHoverCard>
      </Box>
    );
  }

  if (props.type === 'list-item') {
    return (
      <DetailsTag.Moment
        moment={{
          ...props.moment,
          worldImageUrl: props.world.imageUrl,
          worldName: props.world.name,
        }}
      />
    );
  }

  if (props.type === 'static') {
    return (
      <DetailsTag.Moment
        moment={{
          ...props.moment,
          worldImageUrl: props.world.imageUrl,
          worldName: props.world.name,
        }}
      />
    );
  }

  return (
    <Box css={{ display: 'flex', minWidth: 0 }}>
      <MomentHoverCard world={props.world} moment={props.moment}>
        <NextLink href={momentHref} passHref prefetch={false}>
          <a style={{ textDecoration: 'none', display: 'block' }}>
            <DetailsTag.Moment
              moment={{
                ...props.moment,
                worldImageUrl: props.world.imageUrl,
                worldName: props.world.name,
              }}
            />
          </a>
        </NextLink>
      </MomentHoverCard>
    </Box>
  );
}
