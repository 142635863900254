import { CountdownPart } from 'types/countdown';

type InlineCountdownTimeProps = {
  variant?: 'padded';
  parts: CountdownPart[];
};

export default function InlineCountdownTime(props: InlineCountdownTimeProps) {
  const { parts, variant } = props;

  return (
    <>
      {parts.map((part, key) => (
        <span key={key}>
          {' '}
          {variant === 'padded' ? part.formattedValue : part.value}
          {part.shortLabel}
        </span>
      ))}
    </>
  );
}
