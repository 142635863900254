import { TimeIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import { isFuture, isPast, parseJSON } from 'date-fns';

import { dateTimeToUnix } from 'utils/dates/dates';

import InlineCountdown from './InlineCountdown';
import Pulse from './Pulse';
import Flex from './base/Flex';
import Text from './base/Text';

interface EditionCountdownProps {
  saleStartsAt: string;
  saleEndsAt: string | null;
}

export default function EditionCountdown(props: EditionCountdownProps) {
  const { saleStartsAt, saleEndsAt } = props;

  if (isFuture(parseJSON(saleStartsAt))) {
    return (
      <TimerWrapper>
        <IconWrapper>
          <TimeIcon />
        </IconWrapper>
        <CountdownText weight="medium">
          Starts in{' '}
          <InlineCountdown timestamp={dateTimeToUnix(props.saleStartsAt)} />
        </CountdownText>
      </TimerWrapper>
    );
  }

  if (!saleEndsAt) {
    return null;
  }

  if (isPast(parseJSON(saleStartsAt)) && isFuture(parseJSON(saleEndsAt))) {
    return (
      <TimerWrapper>
        <Pulse size={20} color="$live" />
        <CountdownText weight="medium">
          Ends in <InlineCountdown timestamp={dateTimeToUnix(saleEndsAt)} />
        </CountdownText>
      </TimerWrapper>
    );
  }

  return null;
}

const CountdownText = styled(Text, {
  color: '$black70',
  span: {
    color: '$black100',
  },
});

const TimerWrapper = styled(Flex, {
  alignItems: 'center',
  gap: '$2',
});

const IconWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  background: '$black5',
  borderRadius: '$round',
  justifyContent: 'center',

  width: '$icon4',
  height: '$icon4',
});
