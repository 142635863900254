import { styled } from '@f8n-frontend/stitches';
import React from 'react';

import Avatar from 'components/base/Avatar';
import AvatarText from 'components/base/AvatarText';
import type { AvatarTextProps } from 'components/base/AvatarText';
import Button from 'components/base/Button';
import type { ButtonProps } from 'components/base/Button';
import Mono from 'components/base/Mono';
import Text from 'components/base/Text';

import { UnsafeAny } from 'types/utils';

const ButtonWithAvatarRoot = styled(Button, {
  '&:disabled': {
    [`${Avatar.Image}`]: {
      opacity: 0.5,
    },
  },
  variants: {
    size: {
      0: {
        paddingLeft: '6px',
        paddingRight: '$3',
        [`${Text}`]: {
          fontSize: '$1 !important',
        },
        [`${Mono}`]: {
          fontSize: '$mono1 !important',
        },
        [`${Avatar.Image}`]: {
          width: '$avatar2',
          height: '$avatar2',
        },
      },
      1: {
        paddingLeft: '$2',
        paddingRight: '$3',
        [`${Text}`]: {
          fontSize: '$2 !important',
        },
        [`${Mono}`]: {
          fontSize: '$mono2 !important',
        },
        [`${Avatar.Image}`]: {
          width: '$avatar3',
          height: '$avatar3',
        },
      },
      2: {
        paddingLeft: '$2',
        paddingRight: '$4',
        [`${Text}`]: {
          fontSize: '$2 !important',
        },
        [`${Mono}`]: {
          fontSize: '$mono2 !important',
        },
        [`${Avatar.Image}`]: {
          width: '$avatar4',
          height: '$avatar4',
          marginRight: '10px',
        },
      },
    },
    shape: {
      circle: {},
      square: {},
    },
    hasImage: {
      true: {},
    },
  },
  compoundVariants: [
    {
      shape: 'square',
      size: 0,
      css: {
        borderRadius: '$1',
      },
    },
    {
      shape: 'square',
      size: 1,
      css: {
        borderRadius: '$2',
      },
    },
    {
      shape: 'square',
      size: 2,
      css: {
        borderRadius: '$2',
      },
    },
    // Lack of image
    {
      hasImage: false,
      size: 0,
      css: {
        paddingX: '$3',
      },
    },
    {
      hasImage: false,
      size: 1,
      css: {
        paddingX: '$3',
      },
    },
    {
      hasImage: false,
      size: 2,
      css: {
        paddingX: '$4',
      },
    },
  ],
});

export type ButtonWithAvatarProps = ButtonProps &
  Exclude<AvatarTextProps, 'size'> & {
    as?: React.ElementType;
    css?: UnsafeAny;
    publicKey?: string;
  };

function ButtonWithAvatar(props: ButtonWithAvatarProps) {
  const { as, css, imageUrl, mono, publicKey, shape, size, text, ...rest } =
    props;
  return (
    <ButtonWithAvatarRoot
      as={as}
      css={css}
      // Not sure why we have to do this, but stitches seems to always render
      // hasImage as true – even if we pass false into it. So we're forced to
      // pass undefined here to make sure the right spacing is applied.
      //
      // eslint-disable-next-line
      hasImage={!!imageUrl ? true : undefined}
      shape={shape}
      size={size}
      {...rest}
    >
      <AvatarText
        imageUrl={imageUrl}
        mono={mono}
        publicKey={publicKey}
        shape={shape}
        size={size}
        text={text}
      />
    </ButtonWithAvatarRoot>
  );
}

export default ButtonWithAvatar;
