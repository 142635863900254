import { TimeIcon } from '@f8n/icons';
import { ICON_SIZE_MAP } from '@f8n/icons/dist/helpers/size';
import { styled } from '@f8n-frontend/stitches';
import { isFuture, isPast, parseJSON } from 'date-fns';
import NextLink from 'next/link';
import { match } from 'ts-pattern';
import type { Address } from 'viem';

import EditionCountdown from 'components/EditionCountdown';
import InlineCountdown from 'components/InlineCountdown';
import { MomentCalendarButton } from 'components/MomentCalendarButton';
import Stat from 'components/Stat';
import Box from 'components/base/Box';
import Button from 'components/base/Button';
import EthText from 'components/base/EthText';
import Flex from 'components/base/Flex';
import Progress from 'components/base/Progress';
import Text from 'components/base/Text';
import MintPrice from 'components/collections/MintPrice';
import { MemoizedAvatarStack } from 'components/profiles/ProfileCollectors';
import { OfferProgressCircle } from 'components/transactions/offer/OfferProgressCircle';
import UserTag from 'components/users/UserTag';
import useAnalytics from 'contexts/analytics/useAnalytics';
import { MOMENTS_CTA } from 'copy/moments';

import { ApiMomentFragment } from 'gql/api/api-fragments.generated';
import { useCollectionOwnersSplits } from 'hooks/queries/hasura/collections/use-collection-owners-splits';
import useModal from 'hooks/use-modal';
import { ChainId } from 'lib/chains';
import { getMintPrice } from 'utils/collections';
import { dateTimeToUnix, formatDateTime } from 'utils/dates/dates';
import { getDropStartTime } from 'utils/drops';
import {
  abbreviateValue,
  formatETHRounded,
  formatETHWithSuffix,
  getMintedSupplyPercent,
} from 'utils/formatters';
import { isNumberType } from 'utils/helpers';
import { mapApiMediaToLaunchMedia } from 'utils/media';
import { getPath } from 'utils/router';
import { pluralizeWord } from 'utils/strings';

import { Drop } from 'types/Drop';
import {
  LimitedEditionCollectionSale,
  TimedEditionCollectionSale,
} from 'types/EditionSale';
import { SellableItemType } from 'types/Marketplace';
import { ActiveAuction, ActiveBuyNow, MarketNft } from 'types/Nft';
import { LaunchMediaAsset } from 'types/media';

const MarketStatLabel = styled(Stat.Label, {
  svg: {
    width: ICON_SIZE_MAP[0],
    height: ICON_SIZE_MAP[0],
    marginRight: '$1',
  },
});
MarketStatLabel.defaultProps = {
  size: {
    '@initial': 0,
    '@bp1': 2,
  },
  lineHeight: {
    '@initial': 0,
    '@bp1': 1,
  },
  color: 'dim',
  weight: 'medium',
};

const PrimaryCtaButton = styled(Button, {
  minWidth: 200,
  '@bp1-max': {
    width: '100%',
  },
});
PrimaryCtaButton.defaultProps = {
  variant: 'primary',
};

const CtaButton = styled(Button, {
  minWidth: 200,
  width: 'max-content',
  '@bp1-max': {
    width: '100%',
  },
});
CtaButton.defaultProps = {
  variant: 'secondary',
};

const CtaRow = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$2',
});

const MarketStatValue = styled(Stat.Value, {
  whiteSpace: 'nowrap',
  lineHeight: 1,
  '@bp2-max': {
    svg: {
      display: 'none',
    },
  },
});

MarketStatValue.defaultProps = {
  size: {
    '@initial': 3,
    '@bp2': 5,
  },
  weight: 'medium',
};

const StatRow = styled('div', {
  display: 'flex',
  gap: '$5',
});

const ProgressRow = styled('div', {
  paddingY: '$3',
  '@bp1': {
    paddingY: '$6',
  },
});

const ShowcaseMarketContainer = styled('div', {
  [`${StatRow} + ${CtaRow}, ${StatRow} + ${CtaButton}, ${StatRow} + ${CtaButton}`]:
    {
      marginTop: '$5',
      '@bp1': {
        marginTop: '$6',
      },
    },
});

const LAUNCH_CTA_TEXT = {
  details: 'View details',
  drop: 'View drop',
  edition: 'View edition',
  mint: 'Mint now',
} as const;

const NFT_CTA_TEXT = {
  bid: 'Place bid',
  buy: 'Buy',
  offer: 'Make offer',
  view: 'View NFT',
  details: 'View details',
} as const;

type LaunchMarketProps = {
  slug: string;
  chainId: ChainId;
  contractAddress: Address;
  totalSales: number;
  name: string;
};

interface TimedEditionMarketProps extends LaunchMarketProps {
  href: string;
  media: LaunchMediaAsset | null;
  nftCount: number;
  sale: TimedEditionCollectionSale;
}

function TimedEditionMarket(props: TimedEditionMarketProps) {
  const analytics = useAnalytics();
  const modal = useModal();
  const {
    chainId,
    contractAddress,
    href,
    media,
    name,
    nftCount,
    sale,
    totalSales,
  } = props;
  const { mintPrice } = sale;

  const type = 'edition' as const;

  if (sale.status === 'SCHEDULED') {
    return (
      <ShowcaseMarketContainer>
        <StatRow>
          <Box>
            <MarketStatLabel>
              <TimeIcon />
              <span>Scheduled</span>
            </MarketStatLabel>
            <MarketStatValue>
              {formatDateTime(parseJSON(sale.startTime))}
            </MarketStatValue>
          </Box>
          <Box>
            <MarketStatLabel>Price</MarketStatLabel>
            <MarketStatValue>
              {mintPrice === 0 ? 'Free' : formatETHRounded(mintPrice)}
              {mintPrice > 0 && <EthText>ETH</EthText>}
            </MarketStatValue>
          </Box>
        </StatRow>
        <CtaLinkButton href={href} type={type}>
          {LAUNCH_CTA_TEXT.details}
        </CtaLinkButton>
      </ShowcaseMarketContainer>
    );
  }

  if (sale.status === 'ENDED') {
    return (
      <ShowcaseMarketContainer>
        <StatRow>
          <Box>
            <MarketStatLabel>Minted</MarketStatLabel>
            <MarketStatValue>{abbreviateValue(nftCount)}</MarketStatValue>
          </Box>
          {mintPrice > 0 && (
            <Box>
              <MarketStatLabel>Total sales</MarketStatLabel>
              <MarketStatValue>
                {formatETHRounded(totalSales)}
                <EthText>ETH</EthText>
              </MarketStatValue>
            </Box>
          )}
        </StatRow>
        <CtaRow>
          <CtaLinkButton href={href} type={type}>
            {LAUNCH_CTA_TEXT.edition}
          </CtaLinkButton>
          <ShowcaseCollectors contractAddress={contractAddress} />
        </CtaRow>
      </ShowcaseMarketContainer>
    );
  }
  return (
    <ShowcaseMarketContainer>
      <StatRow>
        <Box>
          <MarketStatLabel>Minted</MarketStatLabel>
          <MarketStatValue>{abbreviateValue(nftCount)}</MarketStatValue>
        </Box>
        <Box>
          <MarketStatLabel>Price</MarketStatLabel>
          <MarketStatValue>
            {mintPrice === 0 ? 'Free' : formatETHRounded(mintPrice)}
            {mintPrice > 0 && <EthText>ETH</EthText>}
          </MarketStatValue>
        </Box>
      </StatRow>
      <CtaRow
        css={{
          marginBottom: '$5',
        }}
      >
        <PrimaryCtaButton
          onClick={() => {
            analytics.track({
              name: 'clicked_showcase_mint_cta',
              type,
            });
            modal.setModal({
              category: 'EDITION',
              market: 'FND',
              mintPrice: sale.mintPrice,
              type: 'MINT',
              collection: {
                chainId,
                contractAddress,
                name,
              },
              media,
            });
          }}
        >
          {LAUNCH_CTA_TEXT.mint}
        </PrimaryCtaButton>
        <ShowcaseCollectors contractAddress={contractAddress} />
      </CtaRow>
      <EditionCountdown
        saleEndsAt={sale.endTime}
        saleStartsAt={sale.startTime}
      />
    </ShowcaseMarketContainer>
  );
}

interface LimitedEditionMarketProps extends LaunchMarketProps {
  href: string;
  nftCount: number;
  sale: LimitedEditionCollectionSale;
  media: LaunchMediaAsset | null;
}

function LimitedEditionMarket(props: LimitedEditionMarketProps) {
  const analytics = useAnalytics();
  const modal = useModal();
  const {
    chainId,
    href,
    nftCount,
    contractAddress,
    media,
    name,
    totalSales,
    sale,
  } = props;
  const { mintPrice } = sale;

  const type = 'edition' as const;

  if (sale.status === 'SCHEDULED') {
    return (
      <ShowcaseMarketContainer>
        <StatRow>
          <Box>
            <MarketStatLabel>
              <TimeIcon />
              <span>Scheduled</span>
            </MarketStatLabel>
            <MarketStatValue>
              {formatDateTime(parseJSON(sale.startTime))}
            </MarketStatValue>
          </Box>
          <Box>
            <MarketStatLabel>Price</MarketStatLabel>
            <MarketStatValue>
              {mintPrice === 0 ? 'Free' : formatETHRounded(mintPrice)}
              {mintPrice > 0 && <EthText>ETH</EthText>}
            </MarketStatValue>
          </Box>
        </StatRow>
        <CtaLinkButton href={href} type={type}>
          {LAUNCH_CTA_TEXT.details}
        </CtaLinkButton>
      </ShowcaseMarketContainer>
    );
  }

  if (sale.status === 'MINTED_OUT') {
    return (
      <ShowcaseMarketContainer>
        <StatRow>
          <Box>
            <MarketStatLabel>Minted</MarketStatLabel>
            <Flex>
              <MarketStatValue>
                {abbreviateValue(nftCount)}
                <Text as="span" color="dim">
                  /{sale.maxTokenId}
                </Text>
              </MarketStatValue>
            </Flex>
          </Box>
          {mintPrice > 0 && (
            <Box>
              <MarketStatLabel>Total sales</MarketStatLabel>
              <MarketStatValue>
                {formatETHRounded(totalSales)}
                <EthText>ETH</EthText>
              </MarketStatValue>
            </Box>
          )}
        </StatRow>
        <CtaRow>
          <CtaLinkButton href={href} type={type}>
            {LAUNCH_CTA_TEXT.edition}
          </CtaLinkButton>
          <ShowcaseCollectors contractAddress={contractAddress} />
        </CtaRow>
      </ShowcaseMarketContainer>
    );
  }

  const tokensMintedPercent = getMintedSupplyPercent({
    minted: nftCount,
    supply: sale.maxTokenId,
  });

  return (
    <ShowcaseMarketContainer>
      <StatRow>
        <Box>
          <MarketStatLabel>Minted</MarketStatLabel>
          <MarketStatValue>
            {abbreviateValue(nftCount)}
            <Text as="span" color="dim">
              /{sale.maxTokenId}
            </Text>
          </MarketStatValue>
        </Box>
        <Box>
          <MarketStatLabel>Price</MarketStatLabel>
          <MarketStatValue>
            {mintPrice === 0 ? 'Free' : formatETHRounded(mintPrice)}
            {mintPrice > 0 && <EthText>ETH</EthText>}
          </MarketStatValue>
        </Box>
      </StatRow>
      <ProgressRow>
        <Progress.Animated value={tokensMintedPercent} />
      </ProgressRow>
      <CtaRow>
        <PrimaryCtaButton
          onClick={() => {
            analytics.track({
              name: 'clicked_showcase_mint_cta',
              type,
            });
            modal.setModal({
              category: 'EDITION',
              market: 'FND',
              mintPrice,
              type: 'MINT',
              collection: {
                chainId,
                contractAddress,
                name,
              },
              media,
            });
          }}
        >
          {LAUNCH_CTA_TEXT.mint}
        </PrimaryCtaButton>
        <ShowcaseCollectors contractAddress={contractAddress} />
      </CtaRow>
    </ShowcaseMarketContainer>
  );
}

interface DropMarketProps extends LaunchMarketProps {
  href: string;
  nftCount: number;
  sale: Drop['sale'];
  media: Drop['media'];

  /** @deprecated use sale instead */
  clearingPrice: Drop['clearingPrice'];
  /** @deprecated use sale instead */
  earlyAccessStartTime: Drop['earlyAccessStartTime'];
  /** @deprecated use sale instead */
  generalAvailabilityStartTime: Drop['generalAvailabilityStartTime'];
  /** @deprecated use sale instead */
  mintPrice: Drop['mintPrice'];
  /** @deprecated use sale instead */
  saleType: Drop['saleType'];
  /** @deprecated use sale instead */
  status: Drop['status'];
}

function DropMarket(props: DropMarketProps) {
  const {
    chainId,
    contractAddress,
    href,
    name,
    nftCount,
    sale,
    slug,
    totalSales,
    media,
    // TODO (highlight-buy): remove these deprecated props
    clearingPrice,
    earlyAccessStartTime,
    generalAvailabilityStartTime,
    mintPrice: dropMintPrice,
    saleType,
    status,
  } = props;

  const maxTokenId = sale ? sale.maxTokenId : null;

  const isMintedOut = status === 'MINTED_OUT';
  const isMinting = status === 'OPEN_PRESALE' || status === 'OPEN_PUBLIC';
  const startTime = getDropStartTime({
    earlyAccessStartTime,
    generalAvailabilityStartTime,
  });
  const analytics = useAnalytics();
  const modal = useModal();
  const type = 'drop' as const;

  const mintPrice = getMintPrice({
    saleType,
    mintPrice: dropMintPrice,
    clearingPrice,
  });

  const getMintButton = () => {
    return match(sale)
      .with({ type: 'FND_FIXED_PRICE' }, (sale) => {
        return (
          <PrimaryCtaButton
            onClick={() => {
              analytics.track({
                name: 'clicked_showcase_mint_cta',
                type,
              });
              modal.setModal({
                type: 'MINT',
                category: 'DROP',
                market: 'FND',
                mintPrice: sale.mintPrice,
                collection: {
                  chainId,
                  contractAddress,
                  name,
                },
                media: mapApiMediaToLaunchMedia(media),
                sale,
              });
            }}
          >
            {LAUNCH_CTA_TEXT.mint}
          </PrimaryCtaButton>
        );
      })
      .with(
        { type: 'HIGHLIGHT_STAGGERED_DUTCH_AUCTION', status: 'AVAILABLE' },
        { type: 'HIGHLIGHT_STAGGERED_DUTCH_AUCTION', status: 'LIVE' },
        () => {
          return (
            <NextLink href={getPath.collection.page(slug)} passHref>
              <Button as="a" variant="primary">
                {LAUNCH_CTA_TEXT.mint}
              </Button>
            </NextLink>
          );
        }
      )
      .otherwise(() => (
        <NextLink href={getPath.collection.page(slug)} passHref>
          <Button as="a" variant="primary">
            {LAUNCH_CTA_TEXT.drop}
          </Button>
        </NextLink>
      ));
  };

  if (isMinting) {
    return (
      <ShowcaseMarketContainer>
        <StatRow>
          <Box>
            <MarketStatLabel>Minted</MarketStatLabel>
            <MarketStatValue>
              {isNumberType(nftCount) ? (
                <>
                  {nftCount}
                  {isNumberType(maxTokenId) && (
                    <Text as="span" color="dim">
                      /{maxTokenId}
                    </Text>
                  )}
                </>
              ) : (
                <Box />
              )}
            </MarketStatValue>
          </Box>
          {mintPrice !== null && mintPrice !== 0 && (
            <Box>
              <MarketStatLabel>Price</MarketStatLabel>
              <MarketStatValue>
                <MintPrice mintPrice={mintPrice} size="large" />
              </MarketStatValue>
            </Box>
          )}
        </StatRow>
        {isNumberType(maxTokenId) && (
          <ProgressRow>
            <Progress.Animated
              value={getMintedSupplyPercent({
                minted: nftCount,
                supply: maxTokenId,
              })}
            />
          </ProgressRow>
        )}
        <CtaRow>
          {getMintButton()}
          <ShowcaseCollectors contractAddress={contractAddress} />
        </CtaRow>
      </ShowcaseMarketContainer>
    );
  }

  if (status === 'SCHEDULED' && isFuture(parseJSON(startTime))) {
    return (
      <ShowcaseMarketContainer>
        <StatRow>
          <Box>
            <MarketStatLabel>
              <TimeIcon />
              <span>Scheduled</span>
            </MarketStatLabel>
            <MarketStatValue>
              {formatDateTime(parseJSON(startTime))}
            </MarketStatValue>
          </Box>
          {mintPrice !== null && (
            <Box>
              <MarketStatLabel>Price</MarketStatLabel>
              <MarketStatValue>
                <MintPrice mintPrice={mintPrice} size="large" />
              </MarketStatValue>
            </Box>
          )}
        </StatRow>
        <CtaLinkButton href={href} type={type}>
          {LAUNCH_CTA_TEXT.details}
        </CtaLinkButton>
      </ShowcaseMarketContainer>
    );
  }

  if (isMintedOut) {
    return (
      <ShowcaseMarketContainer>
        <StatRow>
          <Box>
            <MarketStatLabel>Minted</MarketStatLabel>
            <MarketStatValue>
              {nftCount}
              <Text as="span" color="dim">
                /{maxTokenId}
              </Text>
            </MarketStatValue>
          </Box>
          {isNumberType(mintPrice) && mintPrice > 0 && (
            <Box>
              <MarketStatLabel>Total sales</MarketStatLabel>
              <MarketStatValue>
                {formatETHRounded(totalSales)}
                <EthText>ETH</EthText>
              </MarketStatValue>
            </Box>
          )}
        </StatRow>
        <CtaRow>
          <CtaLinkButton href={href} type={type}>
            {LAUNCH_CTA_TEXT.drop}
          </CtaLinkButton>
          <ShowcaseCollectors contractAddress={contractAddress} />
        </CtaRow>
      </ShowcaseMarketContainer>
    );
  }
  return (
    <ShowcaseMarketContainer>
      <MarketStatLabel>
        <TimeIcon />
        <span>Minting soon</span>
      </MarketStatLabel>
      <CtaRow css={{ marginTop: '$5' }}>
        <CtaLinkButton href={href} type={type}>
          {LAUNCH_CTA_TEXT.drop}
        </CtaLinkButton>
      </CtaRow>
    </ShowcaseMarketContainer>
  );
}

interface ShowcaseCollectorsProps {
  contractAddress: Address;
}

function ShowcaseCollectors(props: ShowcaseCollectorsProps) {
  const { contractAddress } = props;

  const launchCollectionOwnersSplits = useCollectionOwnersSplits({
    contractAddress,
  });

  const collectionOwners = launchCollectionOwnersSplits.data;

  if (!collectionOwners || collectionOwners.collectorsCount == 0) return null;

  const text = `${collectionOwners.collectorsCount} ${pluralizeWord(
    'collector',
    collectionOwners.collectorsCount
  )}`;

  const trimmedOwners = collectionOwners.collectors.slice(0, 3);

  return (
    <Flex center css={{ '@bp1-max': { display: 'none' }, gap: '$1' }}>
      <MemoizedAvatarStack
        users={trimmedOwners}
        placement="bottom"
        variant="white"
        size={2}
      />
      <Text size={1} color="dim" weight="medium">
        {text}
      </Text>
    </Flex>
  );
}

interface NftMarketProps {
  href: string;
  nft: MarketNft;
}

function NftMarket(props: NftMarketProps) {
  const { href, nft } = props;
  const { activeAuction, activeOffer, activeBuyNow, lastSale, saleStartsAt } =
    nft;

  const analytics = useAnalytics();
  const modal = useModal();
  const type = 'nft' as const;

  const handleMakeOffer = () => {
    analytics.track({
      name: 'clicked_showcase_make_offer_cta',
    });
    modal.setModal({
      type: 'MAKE_OFFER',
      version: 2,
      nft,
    });
  };

  const handlePlaceBid = (auction: ActiveAuction) => {
    analytics.track({
      name: 'clicked_showcase_place_bid_cta',
    });
    modal.setModal({
      type: 'PLACE_BID',
      version: 2,
      auction,
      nft,
    });
  };

  const isScheduled = saleStartsAt && isFuture(parseJSON(saleStartsAt));

  const displayBuyNow =
    activeBuyNow &&
    !(activeAuction && activeAuction.reservePrice < activeBuyNow.amount);

  const isAuctionOpen = isOpenAuction(activeAuction);

  if (
    activeAuction &&
    activeAuction.state === 'LIVE' &&
    activeAuction.highestBid &&
    activeAuction.highestBid.bidder &&
    activeAuction.expiryDate
  ) {
    return (
      <ShowcaseMarketContainer>
        <StatRow>
          <Box>
            <MarketStatLabel>Current bid</MarketStatLabel>
            <MarketStatValue>
              {formatETHRounded(activeAuction.highestBid?.amount)}
              <EthText>ETH</EthText>
            </MarketStatValue>
            <Flex css={{ paddingY: '$2' }}>
              <UserTag
                nameVariant="prefer-username"
                size={0}
                type="avatar-text"
                user={activeAuction.highestBid.bidder}
              />
            </Flex>
          </Box>
          <Box>
            <MarketStatLabel>Ends in</MarketStatLabel>
            <MarketStatValue>
              <InlineCountdown
                timestamp={dateTimeToUnix(activeAuction.expiryDate)}
              />
            </MarketStatValue>
          </Box>
        </StatRow>
        <Button
          css={{ marginTop: '$2 !important' }}
          variant="primary"
          onClick={() => handlePlaceBid(activeAuction)}
        >
          {NFT_CTA_TEXT.bid}
        </Button>
      </ShowcaseMarketContainer>
    );
  }

  if (
    activeAuction &&
    activeAuction.state === 'ENDED' &&
    activeAuction.highestBid
  ) {
    return (
      <ShowcaseMarketContainer>
        <StatRow>
          <Box>
            <MarketStatLabel>Winning bid</MarketStatLabel>
            <MarketStatValue>
              {formatETHRounded(activeAuction.highestBid?.amount)}
              <EthText>ETH</EthText>
            </MarketStatValue>
          </Box>
          <Box>
            <MarketStatLabel>Bought by</MarketStatLabel>
            <Flex
              css={{
                paddingTop: 6,
                '@bp1': {
                  paddingTop: 7,
                },
              }}
            >
              <UserTag
                nameVariant="prefer-username"
                size={{ '@initial': 1, '@bp1': 2 }}
                type="avatar-text"
                user={activeAuction.highestBid.bidder}
              />
            </Flex>
          </Box>
        </StatRow>
        <CtaLinkButton href={href} type={type}>
          {NFT_CTA_TEXT.view}
        </CtaLinkButton>
      </ShowcaseMarketContainer>
    );
  }

  /**
   * TODO (achi-auction): add !activeOffer check back
   * in here when we de-feature the achi acution
   */
  if (!activeAuction && !activeBuyNow) {
    return (
      <ShowcaseMarketContainer>
        {lastSale && (
          <StatRow>
            <Box>
              <MarketStatLabel>Sold for</MarketStatLabel>
              <MarketStatValue>
                {formatETHRounded(lastSale?.amount)}
                <EthText>ETH</EthText>
              </MarketStatValue>
            </Box>
            <Box>
              <MarketStatLabel>Bought by</MarketStatLabel>
              <Flex
                css={{
                  paddingTop: 6,
                  '@bp1': {
                    paddingTop: 7,
                  },
                }}
              >
                <UserTag
                  nameVariant="prefer-username"
                  size={{ '@initial': 1, '@bp1': 2 }}
                  type="avatar-text"
                  user={lastSale.buyer}
                />
              </Flex>
            </Box>
          </StatRow>
        )}
        <CtaLinkButton href={href} type={type}>
          {NFT_CTA_TEXT.view}
        </CtaLinkButton>
      </ShowcaseMarketContainer>
    );
  }
  return (
    <ShowcaseMarketContainer>
      {activeOffer && (
        <>
          <Flex
            css={{
              alignItems: 'center',
              gap: '$1',
              marginBottom: '$2',
              '@bp1': {
                marginBottom: '$4',
              },
            }}
          >
            <MarketStatLabel css={{ marginBottom: 0 }}>
              Active offer
            </MarketStatLabel>
            <OfferProgressCircle
              expiresAt={activeOffer.expiryDate}
              size={16}
              strokeWidth={3}
            />
            <MarketStatLabel css={{ color: '$black100', marginBottom: 0 }}>
              {formatETHWithSuffix(Number(activeOffer.amount))}
            </MarketStatLabel>
          </Flex>
          {!activeBuyNow && !activeAuction && (
            <CtaButton variant="primary" onClick={handleMakeOffer}>
              {NFT_CTA_TEXT.offer}
            </CtaButton>
          )}
        </>
      )}
      <StatRow
        css={{
          [`${CtaButton}`]: {
            marginTop: '$5',
          },

          '@bp1-max': {
            '& > div': {
              width: '100%',
            },
          },

          '@bp4-max': {
            [`${CtaButton}`]: {
              minWidth: 'auto',
              whiteSpace: 'nowrap',
            },
          },
        }}
      >
        {/* If scheduled & there is buy & reserve, show lower price.
        if scheduled & only 1 price, show price.
        if not scheduled, show both regardless */}
        {isScheduled ? (
          <>
            <Flex css={{ flexDirection: 'column' }}>
              <Schedule saleStartsAt={saleStartsAt} />
              <CtaLinkButton href={href} type={type} variant="secondary">
                {NFT_CTA_TEXT.details}
              </CtaLinkButton>
            </Flex>
            {displayBuyNow ? (
              <BuyNowButton
                activeBuyNow={activeBuyNow}
                href={href}
                type={type}
                isScheduled
                variant={isAuctionOpen ? 'secondary' : 'primary'}
              />
            ) : (
              isAuctionOpen && (
                <AuctionCta
                  isScheduled
                  reservePrice={activeAuction.reservePrice}
                  onPlaceBid={() => handlePlaceBid(activeAuction)}
                />
              )
            )}
          </>
        ) : (
          <>
            {isAuctionOpen && (
              <AuctionCta
                onPlaceBid={() => handlePlaceBid(activeAuction)}
                reservePrice={activeAuction.reservePrice}
                isScheduled={false}
              />
            )}
            {activeBuyNow && (
              <BuyNowButton
                activeBuyNow={activeBuyNow}
                href={href}
                type={type}
                variant={isAuctionOpen ? 'secondary' : 'primary'}
              />
            )}
          </>
        )}
      </StatRow>
    </ShowcaseMarketContainer>
  );
}

const isOpenAuction = (
  auction: ActiveAuction | null
): auction is ActiveAuction => {
  if (!auction) return false;

  return auction.state === 'OPEN';
};

interface MomentMarketProps {
  moment: ApiMomentFragment;
  href: string;
  bookmarkId: string | null;
}

function MomentMarket(props: MomentMarketProps) {
  const { moment, href } = props;

  const isMomentActive = isPast(parseJSON(moment.startsAt));

  return (
    <Box css={{ display: 'flex', gap: '$2', justifyContent: 'center' }}>
      <NextLink passHref href={href}>
        <Button size={0} variant="primary" as="a">
          {MOMENTS_CTA.VIEW}
        </Button>
      </NextLink>
      {!isMomentActive && (
        <MomentCalendarButton
          bookmarkId={props.bookmarkId}
          moment={moment}
          variant="subtle"
        />
      )}
    </Box>
  );
}

interface ScheduleProps {
  saleStartsAt: string;
  label?: string;
}

function Schedule(props: ScheduleProps) {
  const { saleStartsAt, label = 'Scheduled' } = props;
  return (
    <Box>
      <MarketStatLabel>
        <TimeIcon />
        <span>{label}</span>
      </MarketStatLabel>
      <MarketStatValue>
        {formatDateTime(parseJSON(saleStartsAt))}
      </MarketStatValue>
    </Box>
  );
}

interface BuyNowButtonProps {
  activeBuyNow: ActiveBuyNow;
  href: string;
  type: SellableItemType;
  variant?: 'primary' | 'secondary';
  isScheduled?: boolean;
}

function BuyNowButton(props: BuyNowButtonProps) {
  const {
    activeBuyNow,
    href,
    type,
    variant = 'secondary',
    isScheduled,
  } = props;
  return (
    <Box>
      <Box>
        <MarketStatLabel>Buy Now</MarketStatLabel>
        <MarketStatValue>
          {formatETHRounded(activeBuyNow.amount)}
          <EthText>ETH</EthText>
        </MarketStatValue>
      </Box>
      {!isScheduled && (
        <CtaLinkButton href={href} type={type} variant={variant}>
          {/* TODO: add Modal to buy directly from this surface, and add clicked_showcase_buy_now_cta when triggering modal */}
          {NFT_CTA_TEXT.buy}
        </CtaLinkButton>
      )}
    </Box>
  );
}
interface AuctionCtaProps {
  isScheduled: boolean;
  onPlaceBid: () => void;
  reservePrice: number;
}

function AuctionCta(props: AuctionCtaProps) {
  const { reservePrice, onPlaceBid, isScheduled } = props;
  return (
    <Box>
      <Box>
        <MarketStatLabel>Reserve</MarketStatLabel>
        <MarketStatValue>
          {formatETHRounded(reservePrice)}
          <EthText>ETH</EthText>
        </MarketStatValue>
      </Box>
      {!isScheduled && (
        <CtaButton
          css={{
            '@bp1-max': {
              minWidth: 'auto',
            },
          }}
          variant="primary"
          onClick={onPlaceBid}
        >
          {NFT_CTA_TEXT.bid}
        </CtaButton>
      )}
    </Box>
  );
}

type CtaLinkButtonProps = {
  children: React.ReactNode;
  href: string;
  type: SellableItemType;
  variant?: 'primary' | 'secondary';
};

function CtaLinkButton(props: CtaLinkButtonProps) {
  const analytics = useAnalytics();

  return (
    <NextLink href={props.href} passHref>
      <CtaButton
        as="a"
        variant={props.variant || 'secondary'}
        onClick={() => {
          analytics.track({
            name: 'clicked_showcase_view_cta',
            type: props.type,
          });
        }}
      >
        {props.children}
      </CtaButton>
    </NextLink>
  );
}
const ShowcaseMarket = {
  LimitedEdition: LimitedEditionMarket,
  TimedEdition: TimedEditionMarket,
  Drop: DropMarket,
  Nft: NftMarket,
  Moment: MomentMarket,
};

export default ShowcaseMarket;
