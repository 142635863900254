import { CollectionFilter } from 'types/Collection';
import { NftFilter } from 'types/Nft';

/**
 * - Narrows a collection object down to a set of inputs that can be passed down to API queries.
 * - Note that passing a larger object into a API query/mutation will lead to runtime errors.
 */
export const getCollectionFilter = (
  collection: CollectionFilter
): CollectionFilter => {
  // Do not change this to spread all collection fields
  // Doing so creates a bug when making GraphQL requests
  return {
    chainId: collection.chainId,
    contractAddress: collection.contractAddress,
  };
};

/**
 * - Narrows a Token object down to a set of inputs that can be passed down to API queries.
 * - Note that passing a larger object into a API query/mutation will lead to runtime errors.
 */
export const getTokenFilter = (nft: NftFilter): NftFilter => {
  // Do not change this to spread all fields
  // Doing so creates a bug when making GraphQL requests
  return {
    chainId: nft.chainId,
    contractAddress: nft.contractAddress,
    tokenId: nft.tokenId,
  };
};
