import { styled } from '@f8n-frontend/stitches';

import Frame from 'components/Frame';
import { MEDIA_STACK_SELECTOR } from 'components/MediaStack';
import { NFT_MEDIA_SELECTOR } from 'components/NftMedia';

const ShowcaseFrameMediaContainer = styled('div', {
  padding: '8%',
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const MEDIA_CONTAINER_SELECTOR = `${ShowcaseFrameMediaContainer}`;

const MAX_FRAME_HEIGHT = 720;

const ShowcaseFrameRoot = styled(Frame, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 420,

  '@bp0': {
    minHeight: 480,
  },

  '@bp1': {
    minHeight: 560,
  },

  '@bp2': {
    minHeight: 640,
  },

  '@bp3': {
    minHeight: MAX_FRAME_HEIGHT,
  },

  a: {
    height: '100%',
    width: '100%',
  },

  variants: {
    controlAspectRatioVia: {
      // Use this when the media has a fixed aspect ratio
      container: {
        [MEDIA_CONTAINER_SELECTOR]: {
          aspectRatio: '1 / 1',
          maxHeight: MAX_FRAME_HEIGHT,
        },
      },
      // Use this when the media child has a dynamic aspect ratio
      media: {
        a: {
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },

        [MEDIA_CONTAINER_SELECTOR]: {
          flexGrow: 1,
        },

        [`${MEDIA_STACK_SELECTOR}, ${NFT_MEDIA_SELECTOR}`]: {
          aspectRatio: '1 / 1',
          width: '100%',

          '@bp0': {
            aspectRatio: '1 / 1',
            maxHeight: 480,
          },

          '@bp1': {
            aspectRatio: '1 / 1',
            maxHeight: 560,
          },

          '@bp3': {
            aspectRatio: '1 / 0.7',
            maxHeight: MAX_FRAME_HEIGHT,
          },
        },
      },
    },
  },
});

const ShowcaseFrame = {
  Root: ShowcaseFrameRoot,
  MediaContainer: ShowcaseFrameMediaContainer,
};

export default ShowcaseFrame;
