import { Address } from 'viem';
import { z } from 'zod';

import { formatEther } from 'utils/units';

import { UserLight } from 'types/Account';

import { addressValueSchema, hexValueSchema } from './shared';

export const eventIdSchema = z.string().transform((val, ctx) => {
  const [transactionHash] = val.split('-');
  try {
    return hexValueSchema.parse(transactionHash);
  } catch (_err) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Not a valid hex value',
    });
    return z.NEVER;
  }
});

const buildEmptyUser = (publicKey: Address): UserLight => {
  return {
    publicKey,
    name: null,
    profileImageUrl: null,
    username: null,
  };
};

export const userLightSchema = z.object({
  name: z.string().nullable(),
  profileImageUrl: z.string().nullable(),
  username: z.string().nullable(),
  publicKey: addressValueSchema,
});

const eventBaseSchema = z.object({
  blockTimestamp: z.coerce.date(),
  data: z.object({
    transactionHash: hexValueSchema,
  }),
  user: userLightSchema,
});

const eventBaseSchemaWithValue = eventBaseSchema.extend({
  data: z.object({
    transactionHash: hexValueSchema,
    amountInETH: z.coerce.number(),
  }),
});

const eventTypeMap = {
  TRANSFER: 'TRANSFER',
  MINT: 'MINT_BY_CREATOR',
  LIST: 'AUCTION_LISTED',
  BID: 'AUCTION_BID_PLACED',
  UNLIST: 'AUCTION_UNLISTED',
  PRICE_CHANGE: 'AUCTION_CHANGED',
  BUY_NOW_PRICE_ACCEPT: 'BUY_NOW_ACCEPTED',
  BUY_NOW_PRICE_CANCEL: 'BUY_NOW_CANCELED',
  BUY_NOW_PRICE_CHANGE: 'BUY_NOW_CHANGED',
  BUY_NOW_PRICE_SET: 'BUY_NOW_SET',
  OFFER_ACCEPT: 'OFFER_ACCEPTED',
  OFFER_CHANGE: 'OFFER_CHANGED',
  OFFER_CREATE: 'OFFER_MADE',
  OFFER_OUTBID: 'OFFER_OUTBID',
  PRIVATE_SALE: 'PRIVATE_SALE',
  NFT_ADDED_TO_EXHIBITION: 'AUCTION_LISTED_IN_WORLD',
} as const;

type ProvenanceEventType = keyof typeof eventTypeMap;

const createEventBaseSchema = <EventType extends ProvenanceEventType>(
  eventType: EventType
) => {
  const eventTypeLiteral = z.literal(eventType);

  return eventBaseSchema
    .extend({
      eventType: eventTypeLiteral,
    })
    .transform((res) => ({
      type: eventTypeMap[eventTypeLiteral.value],
      createdAt: res.blockTimestamp,
      txHash: res.data.transactionHash,
      user: res.user,
    }));
};

const createEventBaseSchemaWithValue = <EventType extends ProvenanceEventType>(
  eventType: EventType
) => {
  const eventTypeLiteral = z.literal(eventType);

  return eventBaseSchemaWithValue
    .extend({
      eventType: eventTypeLiteral,
    })
    .transform((res) => ({
      type: eventTypeMap[eventTypeLiteral.value],
      amountInEth: res.data.amountInETH,
      createdAt: res.blockTimestamp,
      txHash: res.data.transactionHash,
      user: res.user,
    }));
};

export const saleEventSchema = z.object({
  data: z.object({
    price: z.coerce.string().transform((val) => {
      return Number(formatEther(BigInt(val)));
    }),
  }),
});

const mintEventCreatorSchema = eventBaseSchema
  .extend({
    eventType: z.literal('MINT'),
    data: z.object({
      transactionHash: hexValueSchema,
    }),
  })
  .transform((res) => {
    return {
      type: 'MINT_BY_CREATOR' as const,
      createdAt: res.blockTimestamp,
      txHash: res.data.transactionHash,
      user: res.user,
    };
  });

const mintEventCollectorSchema = eventBaseSchema
  .extend({
    eventType: z.literal('MINT'),
    data: z.object({
      transactionHash: hexValueSchema,
      amountInETH: z.number(),
    }),
  })
  .transform((res) => {
    return {
      type: 'MINT_BY_COLLECTOR' as const,
      createdAt: res.blockTimestamp,
      txHash: res.data.transactionHash,
      amountInEth: res.data.amountInETH,
      user: res.user,
    };
  });

const soldEventSchema = eventBaseSchemaWithValue
  .extend({
    eventType: z.literal('SELL'),
    data: z.object({
      transactionHash: hexValueSchema,
      amountInETH: z.coerce.number(),
      dateEnding: z.coerce.number(),
      fromAddress: addressValueSchema,
    }),
  })
  .transform((res) => ({
    type: 'AUCTION_ENDED' as const,
    amountInEth: res.data.amountInETH,
    createdAt: res.blockTimestamp,
    txHash: res.data.transactionHash,
    user: res.user,
    dateEnding: res.data.dateEnding,
  }));

const settleEventSchema = eventBaseSchema
  .extend({
    eventType: z.literal('SETTLE'),
    data: z.object({
      transactionHash: hexValueSchema,
      amountInETH: z.coerce.number(),
      dateEnding: z.coerce.number(),
      fromAddress: addressValueSchema,
    }),
  })
  .transform((res) => ({
    type: 'AUCTION_FINALIZED' as const,
    amountInEth: res.data.amountInETH,
    createdAt: res.blockTimestamp,
    txHash: res.data.transactionHash,
    settledBy: buildEmptyUser(res.data.fromAddress),
    user: res.user,
    dateEnding: res.data.dateEnding,
  }));

const buyNowEventSchema = eventBaseSchemaWithValue
  .extend({
    eventType: z.literal('BUY_NOW_PRICE_ACCEPT'),
    data: z.object({
      transactionHash: hexValueSchema,
      f8nFee: z.coerce.number(),
      ownerRev: z.coerce.number(),
      creatorFee: z.coerce.number(),
    }),
  })
  .transform((res) => ({
    type: 'BUY_NOW_ACCEPTED' as const,
    amountInEth: res.data.creatorFee + res.data.ownerRev + res.data.f8nFee,
    createdAt: res.blockTimestamp,
    txHash: res.data.transactionHash,
    user: res.user,
  }));

const transferEventSchema = eventBaseSchema
  .extend({
    eventType: z.literal('TRANSFER'),
    data: z.object({
      transactionHash: hexValueSchema,
      fromAddress: addressValueSchema,
      toAddress: addressValueSchema,
    }),
  })
  .transform((res) => ({
    type: 'TRANSFER' as const,
    createdAt: res.blockTimestamp,
    txHash: res.data.transactionHash,
    user: res.user,
    fromUser: buildEmptyUser(res.data.fromAddress),
    toUser: buildEmptyUser(res.data.toAddress),
  }));

const worldAssociationEventSchema = eventBaseSchema
  .extend({
    id: z.string(),
    eventType: z.literal('NFT_ADDED_TO_EXHIBITION'),
    data: z.object({
      exhibitionId: z.coerce.number(),
    }),
  })
  .transform((res) => {
    return {
      type: 'ADDED_TO_WORLD' as const,
      createdAt: res.blockTimestamp,
      user: res.user,
      exhibitionId: res.data.exhibitionId,
      txHash: eventIdSchema.parse(res.id),
    };
  });

export const provenanceEventSchema = z.union([
  /**
   * mint event
   */
  mintEventCollectorSchema,
  mintEventCreatorSchema,
  /**
   * auction events
   */
  soldEventSchema,
  settleEventSchema,
  createEventBaseSchema('UNLIST'),
  createEventBaseSchemaWithValue('LIST'),
  createEventBaseSchemaWithValue('BID'),
  createEventBaseSchemaWithValue('PRICE_CHANGE'),
  /**
   * buy now events
   */
  buyNowEventSchema,
  createEventBaseSchema('BUY_NOW_PRICE_CANCEL'),
  createEventBaseSchemaWithValue('BUY_NOW_PRICE_CHANGE'),
  createEventBaseSchemaWithValue('BUY_NOW_PRICE_SET'),
  /**
   * offer events
   */
  createEventBaseSchemaWithValue('OFFER_ACCEPT'),
  createEventBaseSchemaWithValue('OFFER_CHANGE'),
  createEventBaseSchemaWithValue('OFFER_CREATE'),
  createEventBaseSchemaWithValue('OFFER_OUTBID'),
  /**
   * misc events
   */
  transferEventSchema,
  createEventBaseSchemaWithValue('PRIVATE_SALE'),
  createEventBaseSchemaWithValue('NFT_ADDED_TO_EXHIBITION'),
  worldAssociationEventSchema,
]);
