import Box from 'components/base/Box';
import Tooltip from 'components/base/Tooltip';
import ProgressCircle from 'components/cards/artwork/subcomponents/ProgressCircle';

import useExpiryCountdown from 'hooks/use-expiry-count-down';

import { UnsafeAny } from 'types/utils';

interface OfferProgressCircleProps {
  expiresAt: string;
  size: number;
  strokeWidth: number;
  css?: UnsafeAny;
}

export function OfferProgressCircle(props: OfferProgressCircleProps) {
  const { expiresAt, size, strokeWidth } = props;

  const { percent, timeRemaining } = useExpiryCountdown({
    expiresAt: expiresAt,
  });

  return (
    <Tooltip content={`Expires in ${timeRemaining}`} size={1} offset={1}>
      <Box css={{ cursor: 'pointer' }}>
        <ProgressCircle
          percent={percent}
          size={size}
          strokeWidth={strokeWidth}
        />
      </Box>
    </Tooltip>
  );
}
