import { styled } from '@f8n-frontend/stitches';
import { ComponentProps } from '@stitches/react';
import { match } from 'ts-pattern';

import Avatar, { AvatarProps } from 'components/base/Avatar';
import Mono from 'components/base/Mono';
import Text, { TextProps } from 'components/base/Text';

import { getUsernameOrAddressInfo } from 'utils/helpers';

import { UserLight, UserMicro } from 'types/Account';

type AvatarTextSize = ComponentProps<typeof Container>['size'];

export type AvatarTextProps = Exclude<AvatarProps, 'size'> & {
  mono?: boolean;
  text: string;
  size?: AvatarTextSize;
  weight?: TextProps['weight'];
};

/** Shows an avatar and text. */
function AvatarText(props: AvatarTextProps) {
  const {
    imageUrl,
    mono = false,
    publicKey,
    shape,
    size = 1,
    text,
    weight = 'semibold',
  } = props;
  return (
    <Container size={size}>
      {(imageUrl || publicKey) && (
        <Avatar
          alt={text}
          imageUrl={imageUrl}
          publicKey={publicKey}
          shape={shape}
          size={size}
        />
      )}
      {mono ? (
        <Mono as="span">{text}</Mono>
      ) : (
        <Text as="span" weight={weight} ellipsis>
          {text}
        </Text>
      )}
    </Container>
  );
}

type UserAvatarTextSharedProps = {
  weight?: TextProps['weight'];
  size?: AvatarTextSize;
};

type UserAvatarTextVariantProps =
  | {
      variant: 'prefer-display-name';
      user: UserMicro | UserLight;
    }
  | {
      variant: 'prefer-username';
      user: UserMicro;
    };

type UserAvatarTextProps = UserAvatarTextSharedProps &
  UserAvatarTextVariantProps;

/** Shows a user's avatar along with their name, username, or address. */
function UserAvatarText(props: UserAvatarTextProps) {
  const { size, user, variant } = props;

  const { hasUsername, nameOrUsername, usernameOrAddress, hasName } =
    getUsernameOrAddressInfo(user);

  const text = match({ variant, hasName, hasUsername })
    .with(
      { variant: 'prefer-display-name', hasName: true },
      () => nameOrUsername
    )
    .with({ variant: 'prefer-display-name' }, () => usernameOrAddress)
    .with({ variant: 'prefer-username' }, () => usernameOrAddress)
    .exhaustive();

  return (
    <AvatarText
      imageUrl={user.profileImageUrl}
      mono={!hasUsername}
      publicKey={user.publicKey}
      size={size}
      weight={props.weight}
      text={text}
    />
  );
}

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  maxWidth: '100%',
  [`${Text}, ${Mono}`]: {
    color: 'currentColor',
    lineHeight: '$1',
    whiteSpace: 'nowrap',
  },
  variants: {
    size: {
      0: {
        [`${Avatar.Image}`]: {
          marginRight: '$1',
        },
        [`${Text}`]: {
          fontSize: '$1',
        },
        [`${Mono}`]: {
          fontSize: '$mono1',
        },
      },
      1: {
        [`${Avatar.Image}`]: {
          marginRight: '6px',
        },
        [`${Text}`]: {
          fontSize: '$1',
        },
        [`${Mono}`]: {
          fontSize: '$mono1',
        },
      },
      2: {
        [`${Avatar.Image}`]: {
          marginRight: '6px',
        },
        [`${Text}`]: {
          fontSize: '$2',
        },
        [`${Mono}`]: {
          fontSize: '$mono2',
        },
      },
      3: {
        [`${Avatar.Image}`]: {
          marginRight: '$2',
        },
        [`${Text}`]: {
          fontSize: '$2',
        },
        [`${Mono}`]: {
          fontSize: '$mono2',
        },
      },
      4: {
        [`${Avatar.Image}`]: {
          marginRight: '10px',
        },
        [`${Text}`]: {
          fontSize: '$3',
        },
        [`${Mono}`]: {
          fontSize: '$mono3',
        },
      },
    },
  },
});

AvatarText.User = UserAvatarText;
export default AvatarText;
