import NextLink from 'next/link';

import Box from 'components/base/Box';
import Link from 'components/base/Link';
import Text from 'components/base/Text';

interface ShowcaseDescriptionProps {
  description: string;
  href: string;
  onSeeDetailsClick: () => void;
}

export default function ShowcaseDescription(props: ShowcaseDescriptionProps) {
  const { description, href } = props;
  return (
    <Box
      css={{
        '@bp1-max': {
          display: 'none',
        },
      }}
    >
      <Text
        size={2}
        lineHeight={3}
        color="dim"
        css={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          overflow: 'hidden',
          wordBreak: 'break-word',
          textOverflow: 'ellipsis',
          marginBottom: '$2',
        }}
      >
        {description}
      </Text>
      <NextLink href={href} passHref>
        <Link
          color="strong"
          size={1}
          css={{
            display: 'block',
            fontWeight: '$semibold',
          }}
          onClick={props.onSeeDetailsClick}
        >
          See details
        </Link>
      </NextLink>
    </Box>
  );
}
