import { styled, darkMode } from '@f8n-frontend/stitches';
import * as ProgressPrimitive from '@radix-ui/react-progress';
import { useState } from 'react';
import { useTimeoutFn } from 'react-use';

const Root = styled(ProgressPrimitive.Root, {
  width: '100%',
  height: '6px',

  overflow: 'hidden',
  position: 'relative',
  background: '$black5',
  borderRadius: '$round',

  [darkMode]: {
    backgroundColor: '$black30',
    backdropFilter: 'blur(10px)',
  },

  // Fix overflow clipping in Safari
  // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
  zIndex: 0,
});

const Indicator = styled(ProgressPrimitive.Indicator, {
  width: '100%',
  height: '100%',
  borderRadius: '$round',
  backgroundColor: '$black100',
  transition: 'transform $3 $ease',
});

type ProgressProps = ProgressPrimitive.ProgressProps & {
  value: number;
};

function ProgressBase(props: ProgressProps) {
  const { value, ...rest } = props;
  return (
    <Root value={value} {...rest}>
      <Indicator
        style={{
          transform: value
            ? `translateX(-${100 - value}%)`
            : `translateX(-100%)`,
        }}
      />
    </Root>
  );
}

function AnimatedProgress(props: ProgressProps) {
  const [animated, setAnimated] = useState(false);

  useTimeoutFn(() => {
    setAnimated(true);
  }, 500);

  return <ProgressBase {...props} value={animated ? props.value : 0} />;
}

const Progress = Object.assign(ProgressBase, {
  Animated: AnimatedProgress,
});

export default Progress;
