import { styled } from '@f8n-frontend/stitches';

import Link from 'components/base/Link';

const ExternalLink = styled(Link, {
  fontWeight: '$semibold',
});

ExternalLink.defaultProps = {
  variant: 'primary',
};

export default ExternalLink;
