import { css } from '@f8n-frontend/stitches';

import { MediaAsset } from 'types/media';

import NftMedia from './NftMedia';
import { ArtworkModel, ThreeDLabel } from './artworks/media/ArtworkMediaModel';
import Model from './model-media/Model';
import ShowcaseFrame from './showcase/ShowcaseFrame';

interface ShowcaseMediaProps {
  media: MediaAsset;
}

const modelStyles = css({
  width: '100%',
})();

export default function ShowcaseMedia(props: ShowcaseMediaProps) {
  const { media } = props;

  if (media.type === 'model') {
    return (
      <ArtworkModel css={{ boxShadow: 'none', height: '100%' }}>
        <Model src={media.src} className={modelStyles} />
        <ThreeDLabel />
      </ArtworkModel>
    );
  }

  return (
    <ShowcaseFrame.MediaContainer>
      <NftMedia media={media} />
    </ShowcaseFrame.MediaContainer>
  );
}
