import { styled } from '@f8n-frontend/stitches';
import NextLink from 'next/link';

import DarkMode from 'components/DarkMode';
import Body from 'components/base/Body';
import Box from 'components/base/Box';
import Divider from 'components/base/Divider';
import Flex from 'components/base/Flex';
import { H3Heading } from 'components/base/Heading';
import Link from 'components/base/Link';
import Text from 'components/base/Text';
import ExternalLink from 'components/links/ExternalLink';

import Logo from 'assets/images/fnd-logo.svg';
import { HELP_CENTER_HOME } from 'utils/router';

import { PageColorMode } from 'types/page';

import { SiteLink } from './types';

const adminLinks: SiteLink[] = [
  {
    children: 'Privacy Policy',
    href: '/privacy',
  },
  {
    children: 'Terms of Service',
    href: '/terms',
  },
];

const companyLinks: SiteLink[] = [
  {
    children: 'Careers',
    href: '/careers',
  },
  {
    children: 'Help Center',
    href: HELP_CENTER_HOME,
    external: true,
  },
  {
    children: 'Subscribe',
    href: '/newsletters',
  },
];

const connectLinks: SiteLink[] = [
  {
    children: 'X',
    href: 'https://twitter.com/foundation',
    external: true,
  },
  {
    children: 'Instagram',
    href: 'https://instagram.com/withfoundation',
    external: true,
  },
  {
    children: 'Blog',
    href: '/blog',
    external: false,
  },
];

interface MaximalFooterProps {
  footerMode?: PageColorMode;
}

export default function MaximalFooter(props: MaximalFooterProps) {
  const { footerMode } = props;
  const footerColorMode = footerMode === 'LIGHT' ? 'light' : 'dark';
  const date = new Date();
  return (
    <Footer footerMode={footerColorMode}>
      <FooterBody>
        <Box>
          <FooterLogo>
            <NextLink href="/" passHref>
              <FooterLogoLink>
                <Logo />
              </FooterLogoLink>
            </NextLink>
          </FooterLogo>
          <Text weight="medium" css={{ fontSize: 26 }}>
            Foundation
          </Text>
        </Box>
        <OuterGrid>
          <OuterGridBox>
            <InnerGrid>
              <InnerGridBox>
                <FooterHeading>Company</FooterHeading>
                <Links>
                  {companyLinks.map((props, index) => (
                    <FooterLink key={index} {...props} />
                  ))}
                </Links>
              </InnerGridBox>
              <InnerGridBox>
                <FooterHeading>Connect</FooterHeading>
                <Links>
                  {connectLinks.map((props, index) => (
                    <FooterLink key={index} {...props} />
                  ))}
                </Links>
              </InnerGridBox>
            </InnerGrid>
          </OuterGridBox>
        </OuterGrid>
      </FooterBody>
      <Divider />
      <Body
        css={{
          paddingY: '$6',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Text size={0} weight="regular" color="dim">
          © {date.getFullYear()} Foundation Labs
        </Text>
        <Flex css={{ gap: '$4' }}>
          {adminLinks.map((props, index) => (
            <FooterLink
              key={index}
              {...props}
              css={{ color: '$dim', fontSize: '$0' }}
            />
          ))}
        </Flex>
      </Body>
    </Footer>
  );
}

function FooterLink(props: SiteLink) {
  const { external, children, href, css } = props;
  if (external) {
    return (
      <FooterExternalLink
        css={css}
        href={href}
        rel="noopener noreferrer"
        target="_blank"
      >
        {children}
      </FooterExternalLink>
    );
  }
  return (
    <NextLink href={href} passHref>
      <FooterInternalLink css={css}>{children}</FooterInternalLink>
    </NextLink>
  );
}

type FooterProps = {
  footerMode: 'light' | 'dark';
  children: React.ReactNode;
};

function Footer(props: FooterProps) {
  const { footerMode, children } = props;

  if (footerMode === 'dark') {
    return (
      <DarkMode>
        <FooterContainer>{children}</FooterContainer>
      </DarkMode>
    );
  }

  return <FooterContainer>{children}</FooterContainer>;
}

const footerLinkStyles = {
  color: '$black70 !important',
  fontSize: '$1',
  fontWeight: '$regular',
};

const FooterContainer = styled('footer', {});

const FooterLogo = styled(Box, {
  width: '100%',
  marginBottom: '$4',
  svg: {
    width: 42,
  },
});

const FooterBody = styled(Body, {
  display: 'flex',
  paddingY: '$7',
  flexDirection: 'column',
  '@bp2-max': {
    gap: '$6',
  },
  '@bp2': {
    paddingY: '$8',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const Links = styled(Flex, {
  flexDirection: 'column',
  alignItems: 'flex-start',
  a: {
    marginBottom: '$3',

    '@bp2-max': {
      marginBottom: '$2',
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
});

const FooterExternalLink = styled(ExternalLink, {
  ...footerLinkStyles,
});

const FooterInternalLink = styled(Link, {
  ...footerLinkStyles,
});

const FooterLogoLink = styled(Link, {
  ...footerLinkStyles,
});

const FooterHeading = styled(H3Heading, {
  color: '$black100',
  fontSize: '$1',
  fontWeight: '$regular',
  marginBottom: '$3',
  '@bp2': {
    marginBottom: '$6',
  },
});

const OuterGrid = styled(Flex, {
  gap: '$8',
  flexDirection: 'column-reverse',
  flexGrow: 1,
  '@bp2': {
    gap: 0,
    alignItems: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const OuterGridBox = styled(Box, {
  marginLeft: 'auto',
  '&:first-child': {
    width: '100%',
    '@bp2': {
      width: '20%',
    },
    '@bp3': {
      width: '30%',
    },
    '@bp4': {
      width: '40%',
    },
  },
  '&:last-child': {
    width: '100%',
    '@bp2': {
      width: '80%',
    },
    '@bp3': {
      width: '70%',
    },
    '@bp4': {
      width: '60%',
    },
  },
});

const InnerGrid = styled(Flex, {
  gap: '$7',
  flexDirection: 'column',

  '@bp2': {
    gap: 0,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

const InnerGridBox = styled(Box, {
  '@bp2': {
    width: '25%',
  },
});
