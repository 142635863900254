import { pipe } from 'ramda';

import { castNanToZero, castToNumber, castToString } from './helpers';
import { formatEther, parseEther } from './units';

export const ethToNumber = pipe(formatEther, castToNumber, castNanToZero);

export const numberToEth = pipe(castToString, parseEther);

export const isBigInt = (value: unknown): value is bigint => {
  return typeof value === 'bigint';
};
