import {
  DropCollectionIcon,
  EditionCollectionIcon,
  NftIcon,
  MomentIcon,
} from '@f8n/icons';

import Badge from 'components/base/Badge';
import Flex from 'components/base/Flex';
import { PRODUCT_BRANDING } from 'copy/branding';

type ShowcaseItemType = 'drop' | 'edition' | 'nft' | 'moment';
interface ShowcaseBadgeProps {
  type: ShowcaseItemType;
}
export default function ShowcaseBadge(props: ShowcaseBadgeProps) {
  const { type } = props;

  return (
    <Flex
      css={{
        '@bp1-max': {
          display: 'none',
        },
      }}
    >
      {getBadge(type)}
    </Flex>
  );
}

const getBadge = (type: ShowcaseBadgeProps['type']) => {
  switch (type) {
    case 'drop': {
      return (
        <Badge size={1} variant="outline">
          <DropCollectionIcon />
          <span>{PRODUCT_BRANDING.drop.singular}</span>
        </Badge>
      );
    }
    case 'edition': {
      return (
        <Badge size={1} variant="outline">
          <EditionCollectionIcon />
          <span>{PRODUCT_BRANDING.editionCollection.singular}</span>
        </Badge>
      );
    }
    case 'moment': {
      return (
        <Badge size={1} variant="outline">
          <MomentIcon />
          <span>{PRODUCT_BRANDING.moment.singular}</span>
        </Badge>
      );
    }
    default: {
      return (
        <Badge size={1} variant="outline">
          <NftIcon />
          <span>{PRODUCT_BRANDING.nft.singular}</span>
        </Badge>
      );
    }
  }
};
