import { sizes, radii } from '@f8n/tokens';
import { styled } from '@f8n-frontend/stitches';
import Tippy, { TippyProps } from '@tippyjs/react';
import NextLink from 'next/link';
import { ReactNode, useState } from 'react';

import 'tippy.js/animations/shift-away.css';

import Box from 'components/base/Box';
import WorldCard, {
  BackgroundVariants,
  ContentProps,
  getBackgroundStyle,
} from 'components/cards/worlds/WorldCard';

import { ApiMomentFragment } from 'gql/api/api-fragments.generated';
import { useMomentCreators } from 'gql/api/queries/moment-creators.generated';
import usePopoverContainerRef from 'hooks/use-popover-container-ref';
import { optimizeAsset } from 'utils/imgix';
import { DEFAULT_TIPPY_PROPS } from 'utils/popover';
import { getPath } from 'utils/router';

import { UserLight } from 'types/Account';
import { WorldOverview } from 'types/World';

import Button from './base/Button';
import Flex from './base/Flex';
import Text from './base/Text';
import UserStack from './follows/UserStack';

type MomentHoverCardProps = {
  children: ReactNode;
  world: WorldOverview;
  moment: ApiMomentFragment;
  delay?: TippyProps['delay'];
  offset?: TippyProps['offset'];
  placement?: TippyProps['placement'];
};

export function MomentHoverCard(props: MomentHoverCardProps) {
  const { children, delay, world, moment, offset, placement } = props;

  const [shouldRender, setShouldRender] = useState(false);
  const popoverContainerRef = usePopoverContainerRef();

  const momentCreators = useMomentCreators(
    { momentId: moment.id },
    { enabled: shouldRender }
  );

  return (
    <Tippy
      {...DEFAULT_TIPPY_PROPS}
      content={
        // Only render the popover content when the popover is triggered,
        // to avoid firing the queries inside this component before they are needed
        shouldRender ? (
          <MomentCard
            world={world}
            moment={moment}
            variant="normal"
            creators={
              momentCreators.data
                ? momentCreators.data.momentCreators.items
                : []
            }
          />
        ) : null
      }
      delay={delay || DEFAULT_TIPPY_PROPS.delay}
      offset={offset || DEFAULT_TIPPY_PROPS.offset}
      placement={placement || DEFAULT_TIPPY_PROPS.placement}
      interactive
      animation="shift-away"
      onTrigger={() => setShouldRender(true)}
      touch={false}
      appendTo={popoverContainerRef.current}
    >
      <Box css={{ cursor: 'pointer', minWidth: 0 }}>{children}</Box>
    </Tippy>
  );
}

type MomentCardProps = {
  moment: ApiMomentFragment;
  world: WorldOverview;
  creators: UserLight[];
  variant?: BackgroundVariants;
  size?: ContentProps['size'];
};

MomentHoverCard.Card = MomentCard;

const WORLD_AVATAR_SIZE = sizes.avatars[1];

function MomentCard(props: MomentCardProps) {
  const backgroundStyles = getBackgroundStyle({
    backgroundImageUrl: props.moment.posterUrl,
    size: typeof props.size === 'string' ? props.size : 'short',
    variant: props.variant,
  });

  const momentHref = getPath.moment.page({
    momentId: props.moment.id,
    worldSlug: props.world.slug,
  });

  const worldHref = getPath.world.page(props.world.slug);

  const worldImageUrl = props.world.imageUrl;

  const optimizedPosterUrl = worldImageUrl
    ? optimizeAsset(worldImageUrl, {
        q: 70,
        w: WORLD_AVATAR_SIZE,
        h: WORLD_AVATAR_SIZE,
        fit: 'crop',
        dpr: 2,
      })
    : null;

  const getCreators = () => {
    if (props.creators.length > 0) {
      return (
        <MomentCreatorsContainer>
          <Text weight="semibold" size={1} css={{ color: '$white100' }}>
            Works by
          </Text>
          <UserStack users={props.creators} variant="blur" size={1} />
        </MomentCreatorsContainer>
      );
    } else {
      /**
       * a spacer to avoid layout shift when there are no creators
       */
      return <div style={{ height: '45px' }} />;
    }
  };

  return (
    <WorldCard.Root
      ref={null}
      variant={props.variant}
      style={backgroundStyles}
      background="overlay"
    >
      <WorldCard.Content size={props.size || 'short'}>
        <NextLink href={momentHref} passHref>
          <a
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 3,
              textIndent: '-9999px',
            }}
          >
            {props.moment.name}
          </a>
        </NextLink>
        <DetailsContainer>
          <Flex>
            <WorldCard.Details name={props.moment.name} />
          </Flex>

          <NextLink href={worldHref} passHref>
            <Button
              as="a"
              size={0}
              variant="blur"
              css={{
                gap: '6px',
                borderRadius: '$2',
                padding: '$2',
                paddingRight: '$3',
                position: 'relative',
                zIndex: 3,
                maxWidth: '100%',
              }}
            >
              {optimizedPosterUrl && (
                <img
                  style={{
                    width: WORLD_AVATAR_SIZE,
                    height: WORLD_AVATAR_SIZE,
                    borderRadius: radii[1],
                    display: 'block',
                    objectFit: 'cover',
                  }}
                  src={optimizedPosterUrl}
                  alt={props.world.name}
                />
              )}

              <Text size={1} ellipsis css={{ minWidth: 0 }}>
                {props.world.name}
              </Text>
            </Button>
          </NextLink>
        </DetailsContainer>
        {getCreators()}
      </WorldCard.Content>
      <WorldCard.Overlay />
    </WorldCard.Root>
  );
}

const MomentCreatorsContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$1',
  paddingTop: '$5',
  borderTop: 'solid 1px',
  borderColor: '$white15',
  width: '100%',
  justifyContent: 'center',
  zIndex: 3,
  position: 'relative',
});

const DetailsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$4',
  color: '$black100',
  flexGrow: 1,
  maxWidth: '100%',
});
