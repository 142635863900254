import NextLink from 'next/link';
import { ReactNode } from 'react';

import Body from 'components/base/Body';
import Box from 'components/base/Box';
import Flex from 'components/base/Flex';
import Grid from 'components/base/Grid';
import Link from 'components/base/Link';
import { FooterLogoLink } from 'components/links/LogoLink';

import { HELP_CENTER_HOME } from 'utils/router';

import { PageColorMode, PageType } from 'types/page';
import { UnsafeAny } from 'types/utils';

import MaximalFooter from './MaximalFooter';
import { SiteLink } from './types';

const socialLinks: SiteLink[] = [
  {
    children: 'Twitter',
    href: 'https://twitter.com/foundation',
    external: true,
  },
  {
    children: 'Instagram',
    href: 'https://instagram.com/withfoundation',
    external: true,
  },
  {
    children: 'Blog',
    href: '/blog',
    external: false,
  },
];

const otherLinks: SiteLink[] = [
  { children: 'Careers', href: '/careers' },
  {
    children: 'Terms of Service',
    href: '/terms',
  },
  {
    children: 'Privacy',
    href: '/privacy',
  },
  {
    children: 'Help',
    href: HELP_CENTER_HOME,
    external: true,
  },
];

interface FooterProps {
  type?: PageType | undefined;
  css?: UnsafeAny;
  footerMode?: PageColorMode;
}

export default function Footer(props: FooterProps) {
  const { type, css = {}, footerMode } = props;

  switch (type) {
    case 'MINIMAL':
      return null;
    case 'MAXIMAL':
      return <MaximalFooter footerMode={footerMode} />;
    default:
      return (
        <Box css={{ paddingY: '$8', marginTop: 'auto', ...css }}>
          <Body>
            <Grid
              css={{
                gap: '$6',
                gridTemplateColumns: 'repeat(6, 1fr)',
                alignItems: 'flex-start',
                '@bp1': { display: 'flex', alignItems: 'center' },
              }}
            >
              <Box css={{ gridColumn: '1/7' }}>
                <Flex>
                  <FooterLogoLink />
                </Flex>
              </Box>
              <FooterLinkList
                css={{ gridColumn: '1/3', '@bp1': { display: 'flex' } }}
              >
                {socialLinks.map((link, key) => (
                  <FooterLink
                    key={key}
                    href={link.href}
                    external={link.external}
                  >
                    {link.children}
                  </FooterLink>
                ))}
              </FooterLinkList>
              <FooterLinkList
                css={{
                  gridColumn: '3/7',
                  '@bp1': { display: 'flex', marginLeft: 'auto' },
                }}
              >
                {otherLinks.map((link, key) => (
                  <FooterLink
                    key={key}
                    href={link.href}
                    external={link.external}
                  >
                    {link.children}
                  </FooterLink>
                ))}
              </FooterLinkList>
            </Grid>
          </Body>
        </Box>
      );
  }
}

interface FooterLinkListProps {
  css?: UnsafeAny;
  children: ReactNode;
}

function FooterLinkList(props: FooterLinkListProps) {
  const { children, css } = props;
  return <Grid css={{ gap: 5, ...css }}>{children}</Grid>;
}

function FooterLink(props: SiteLink) {
  const { external, children, href } = props;

  const css = {
    display: 'block',
    fontSize: '$1',
    color: '$black60',
    fontWeight: '$semibold',
    textDecoration: 'none',
    marginRight: '$2',
    transition: 'color $1 $ease',
    '&:last-of-type': {
      marginRight: 0,
    },
    '@hover': {
      '&:hover': {
        color: '$black100',
      },
    },
    '@bp1': { marginRight: '$4' },
  };

  if (external) {
    return (
      <Link
        className="footer-link"
        css={css}
        href={href}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </Link>
    );
  }

  return (
    <NextLink href={href} passHref>
      <Link className="footer-link" css={css}>
        {children}
      </Link>
    </NextLink>
  );
}
