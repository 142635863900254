import {
  differenceInSeconds,
  isBefore,
  isFuture,
  isPast,
  parseJSON,
} from 'date-fns';
import { P, match } from 'ts-pattern';

import { DropStartTimes } from 'types/Drop';
import { DutchAuctionDropSale } from 'types/DropSale';

import { numberToEth } from './bigint';
import { clampPercent } from './numbers';

/** @deprecated use getSaleStartDate instead */
export const getDropStartTime = (options: DropStartTimes) => {
  const { earlyAccessStartTime, generalAvailabilityStartTime } = options;

  if (earlyAccessStartTime && generalAvailabilityStartTime) {
    return isBefore(
      parseJSON(earlyAccessStartTime),
      parseJSON(generalAvailabilityStartTime)
    )
      ? earlyAccessStartTime
      : generalAvailabilityStartTime;
  }

  // TODO: make this nullable
  return generalAvailabilityStartTime as string;
};

/**
 * This function calculates and returns the rate of price decay per second.
 *
 * @param {number} startPrice - The initial price at the start of the sale.
 * @param {number} endPrice - The final price at the end of the sale.
 * @param {number} saleDuration - The total duration of the sale in seconds.
 * @returns {number} The rate of decay per second, which is the amount by which the price decreases every second.
 */
export function getRateOfDecay(
  startPrice: number,
  endPrice: number,
  saleDuration: number
): number {
  // Calculate the rate of decay per second
  const rateOfDecay = (startPrice - endPrice) / saleDuration;

  return rateOfDecay;
}

type CalculatePercentElapsedTimeOptions = {
  endsAt: Date;
  startsAt: Date;
  currentTime: Date;
};

export function calculatePercentElapsedTime(
  options: CalculatePercentElapsedTimeOptions
): number {
  const { startsAt, endsAt, currentTime } = options;

  const totalTimeDifference = differenceInSeconds(endsAt, startsAt);

  if (totalTimeDifference <= 0) {
    return 0;
  }

  const elapsedTimeDifference = differenceInSeconds(currentTime, startsAt);

  const percentElapsed = (elapsedTimeDifference / totalTimeDifference) * 100;

  return clampPercent(Math.round(percentElapsed));
}

type CalculatePercentElapsedOptions = {
  endsAt: Date;
  startsAt: Date;
  lastMintedAt: Date | null;
  isMintedOut: boolean;
};

/**
 * Calculates the percentage of elapsed time between the start time and the current or last minted time.
 * Returns the percentage as a number between 0 and 100.
 *
 * @param {CalculatePercentElapsedOptions} options - The options for calculating the percent elapsed time.
 * @returns {number} - The percentage of elapsed time.
 */
export function calculatePercentElapsed(
  options: CalculatePercentElapsedOptions
): number {
  const { endsAt, startsAt, lastMintedAt, isMintedOut } = options;

  // If the start time is in the future, no time has elapsed.
  if (isFuture(startsAt)) {
    return 0;
  }

  // If the auction is not minted out and the end time is in the past, the entire duration has elapsed.
  if (!isMintedOut && isPast(endsAt)) {
    return 100;
  }

  // If the auction is minted out and lastMintedAt is provided, calculate the percentage of elapsed time
  // between the start time and lastMintedAt.
  if (isMintedOut && lastMintedAt) {
    return calculatePercentElapsedTime({
      startsAt,
      endsAt,
      currentTime: lastMintedAt,
    });
  }
  // If the auction is not minted out or lastMintedAt is not provided,
  // calculate the percentage of elapsed time between the start time and the current time.
  return calculatePercentElapsedTime({
    startsAt,
    endsAt,
    currentTime: new Date(),
  });
}

export function deriveFallbackDutchAuctionPrice(
  sale: DutchAuctionDropSale
): bigint {
  return match(sale)
    .with({ clearingPrice: P.not(null) }, ({ clearingPrice }) => {
      return numberToEth(clearingPrice);
    })
    .otherwise(() => {
      // Falls back to max, to avoid promoting lower than valid prices
      return numberToEth(sale.maxPrice);
    });
}
