import NextLink from 'next/link';

import AvatarText, { AvatarTextProps } from 'components/base/AvatarText';
import ButtonWithAvatar, {
  ButtonWithAvatarProps,
} from 'components/base/ButtonWithAvatar';
import Link from 'components/base/Link';
import Text, { TextProps } from 'components/base/Text';
import UserAvatar, { UserAvatarProps } from 'components/base/UserAvatar';
import ProfileHoverCard from 'components/profiles/ProfileHoverCard';

import { useIsHydrated } from 'hooks/use-is-hydrated';
import { getUsernameOrAddressInfo } from 'utils/helpers';

import { UserLight, UserMicro } from 'types/Account';

import type { UserTextSize } from './UserText';
import UserText from './UserText';

type UserTagSharedProps = {
  disableHoverCard?: boolean;
  disableLinks?: boolean;
  // TODO: only require this prop for certain types
};

type UserTagTypeProps =
  | {
      type: 'avatar';
      size?: AvatarTextProps['size'];
      variant?: UserAvatarProps['variant'];
    }
  | {
      type?: 'button';
      size?: ButtonWithAvatarProps['size'];
      variant?: ButtonWithAvatarProps['variant'];
    }
  | {
      type: 'avatar-text';
      size?: AvatarTextProps['size'];
      weight?: TextProps['weight'];
      isDark?: boolean;
    }
  | {
      type: 'text';
      color?: TextProps['color'];
      size?: UserTextSize;
      weight?: TextProps['weight'];
    };

type UserTagNameVariantProps =
  | {
      nameVariant: 'prefer-display-name';
      user: UserMicro | UserLight;
    }
  | {
      nameVariant: 'prefer-username';
      user: UserMicro;
    };

export type UserTagProps = UserTagSharedProps &
  UserTagTypeProps &
  UserTagNameVariantProps;

export default function UserTag(props: UserTagProps) {
  const { disableHoverCard, disableLinks, type, user, nameVariant } = props;

  if (!user) {
    return null;
  }

  const { nameOrUsername, usernameOrAddress, hasUsername, userPath } =
    getUsernameOrAddressInfo(user);

  const sharedProps = {
    disableHoverCard,
    publicKey: user.publicKey,
    userPath,
    disableLinks,
  };

  const renderedName =
    nameVariant === 'prefer-display-name' ? nameOrUsername : usernameOrAddress;

  if (props.type === 'avatar') {
    return (
      <UserTagWrapper {...sharedProps}>
        <UserAvatar
          variant={props.variant}
          alt={renderedName}
          imageUrl={user.profileImageUrl}
          publicKey={user?.publicKey}
          size={props.size}
        />
      </UserTagWrapper>
    );
  }

  if (props.type === 'avatar-text') {
    return (
      <UserTagWrapper isDark={props.isDark} {...sharedProps}>
        <AvatarText.User
          variant={nameVariant}
          user={user}
          size={props.size}
          weight={props.weight}
        />
      </UserTagWrapper>
    );
  }

  if (props.type === 'text') {
    return (
      <UserTagWrapper {...sharedProps}>
        <UserText
          color={props.color}
          variant={nameVariant}
          size={props.size}
          user={user}
          weight={props.weight}
        />
      </UserTagWrapper>
    );
  }

  return (
    <UserTagWrapper {...sharedProps}>
      <ButtonWithAvatar
        as="div"
        imageUrl={user.profileImageUrl}
        mono={!hasUsername}
        publicKey={user.publicKey}
        size={props.size}
        text={renderedName}
        variant={type === 'button' ? props.variant || 'raised' : undefined}
      />
    </UserTagWrapper>
  );
}

UserTag.defaultProps = {
  disableHoverCard: false,
  size: 1,
  type: 'button',
};

// UserTagWrapper decides whether to render HoverCard
type UserTagWrapperProps = {
  children: React.ReactNode;
  disableHoverCard?: boolean;
  disableLinks?: boolean;
  publicKey: string;
  userPath?: string;
  /** TODO: Deprecate and use the DarkMode component upstream instead */
  isDark?: boolean;
};

function UserTagWrapper(props: UserTagWrapperProps) {
  const {
    disableHoverCard = false,
    disableLinks = false,
    userPath,
    children,
    publicKey,
    isDark,
  } = props;

  const isHydrated = useIsHydrated();

  if (!isHydrated) {
    return <>{children}</>;
  }

  const wrappedChildren = disableLinks ? (
    <Text color="dim" ellipsis>
      {children}
    </Text>
  ) : (
    <NextLink href={`/${userPath}`} passHref prefetch={false}>
      <Link isDark={isDark} variant="primary" ellipsis>
        {children}
      </Link>
    </NextLink>
  );

  if (disableHoverCard) {
    return wrappedChildren;
  }

  return (
    <ProfileHoverCard publicKey={publicKey}>{wrappedChildren}</ProfileHoverCard>
  );
}
