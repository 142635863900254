import { styled } from '@f8n-frontend/stitches';

const ShowcaseContainer = styled('section', {
  display: 'grid',
  gridTemplateRows: '1fr',
  gap: '$4',
  width: '100%',

  '@bp0': {
    gridTemplateColumns: '7fr 5fr',
  },

  '@bp3': {
    gap: '$7',
    gridTemplateColumns: '2fr 1fr',
  },
});

export default ShowcaseContainer;
