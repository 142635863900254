import useCountdown from 'hooks/use-countdown';
import { useIsHydrated } from 'hooks/use-is-hydrated';

import InlineCountdownTime from './InlineCountdownTime';

interface InlineCountdownProps {
  timestamp: number;
  variant?: 'padded';
}

export default function InlineCountdown(props: InlineCountdownProps) {
  const { timestamp, variant } = props;
  const countdown = useCountdown(timestamp);

  const isHydrated = useIsHydrated();

  if (!isHydrated) {
    return null;
  }

  return (
    <InlineCountdownTime parts={countdown.countdownParts} variant={variant} />
  );
}
