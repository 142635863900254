import { parseJSON } from 'date-fns';
import { differenceInSeconds } from 'date-fns/fp';
import { useState } from 'react';
import { useHarmonicIntervalFn } from 'react-use';

import { timeRemainingInWords } from 'utils/dates/dates';
import { IS_MAINNET } from 'utils/env';
import { clampPercent } from 'utils/numbers';

interface ExpiryCountdownArgs {
  expiresAt: string;
  duration?: number;
}

interface CalculateProgressPercentageArgs {
  expiryDate: Date;
  duration: number;
}

// If mainnet totalDuration is 24 hours (86400 as seconds)
// If goerli totalDuration is 30 mins (1800 as seconds)
const totalDuration = IS_MAINNET ? 86400 : 1800;

// When 24 hours is remaining percent should be 100%
// When 12 hours is remaining percent should be 50%
// When 0 hours is remaining percent should be 0%
export const calculateProgressPercentage = (
  args: CalculateProgressPercentageArgs
) => {
  const { expiryDate, duration } = args;
  const now = new Date();

  const secondsRemainingUntilExpiryDate = differenceInSeconds(now, expiryDate);

  const secondsRemainingIn24Hours = duration - secondsRemainingUntilExpiryDate;
  const secondsAsPercentProgress = (secondsRemainingIn24Hours / duration) * 100;
  const percentRemaining = 100 - secondsAsPercentProgress;
  return clampPercent(percentRemaining);
};

export default function useExpiryCountdown(args: ExpiryCountdownArgs) {
  const { expiresAt, duration = totalDuration } = args;

  const expiryDate = parseJSON(expiresAt);

  const [percent, setPercent] = useState<number>(
    calculateProgressPercentage({ expiryDate, duration })
  );
  const [timeRemaining, setTimeRemaining] = useState<string>(
    timeRemainingInWords(expiresAt, 'short')
  );

  useHarmonicIntervalFn(() => {
    const percentRemaining = calculateProgressPercentage({
      expiryDate,
      duration,
    });
    const timeRemaining = timeRemainingInWords(expiresAt, 'short');
    setPercent(percentRemaining);
    setTimeRemaining(timeRemaining);
  }, 1000 * 5);

  return {
    timeRemaining,
    percent,
  };
}
