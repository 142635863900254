import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import * as Generated from 'gql/api/queries/world-by-slug.generated';
import { worldCache } from 'utils/cache';

import { World } from 'types/World';

export async function getWorldBySlug(
  variables: Generated.ApiWorldBySlugVariables
): Promise<World> {
  const fetchWorldBySlug = Generated.useWorldBySlug.fetcher(variables);
  const response = await fetchWorldBySlug();

  if (!response.world) {
    throw new Error('World not found', {
      cause: {
        queryVariables: variables,
      },
    });
  }

  return response.world;
}

export default function useWorldBySlug(
  variables: Generated.ApiWorldBySlugVariables,
  options: Pick<
    UseQueryOptions<World, Error>,
    | 'enabled'
    | 'initialData'
    | 'retry'
    | 'refetchOnWindowFocus'
    | 'refetchOnReconnect'
    | 'placeholderData'
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: worldCache.bySlug(variables.slug),
    queryFn: () => getWorldBySlug(variables),
  });
}
