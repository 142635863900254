import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { z } from 'zod';

import * as Query from 'gql/hasura/queries/collection-owners-splits.generated';
import { userLightSchema } from 'schemas/provenance';
import { getAggregateCount } from 'utils/aggregate';

import { UserLight } from 'types/Account';

type Collection = NonNullable<
  Query.CollectionOwnersSplits['collection'][number]
>;

export type CollectionSplit = NonNullable<Collection['splits'][number]>;

export type CollectionOwnersSplits = {
  collectors: UserLight[];
  collectorsCount: number;
  splits: CollectionSplit[];
};

const ownedArtworksSchema = z.object({
  owner: userLightSchema,
});

export async function getCollectionOwnersSplits(variables: {
  contractAddress: string;
}): Promise<CollectionOwnersSplits> {
  const getCollectionOwners =
    Query.useCollectionOwnersSplits.fetcher(variables);

  const res = await getCollectionOwners();

  const collection = res.collection[0];

  if (!collection) {
    return {
      collectors: [],
      collectorsCount: 0,
      splits: [],
    };
  }

  return {
    collectors: collection.ownedArtworks.map((artwork) => {
      return ownedArtworksSchema.parse(artwork).owner;
    }),
    collectorsCount: getAggregateCount(collection.ownersCount),
    splits: collection.splits,
  };
}

export function useCollectionOwnersSplits(
  variables: {
    contractAddress: string;
  },
  options: Pick<
    UseQueryOptions<CollectionOwnersSplits, Error>,
    'enabled' | 'initialData'
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: ['collection', 'owners', 'splits', variables],
    queryFn: () => getCollectionOwnersSplits(variables),
  });
}
